// My mixins
@import "mixins";

// Core Bootstrap variables and framework.
@import "../../vendor/bootstrap-4.0.0-beta/scss/functions";
@import "../../vendor/bootstrap-4.0.0-beta/scss/variables";
@import "custom"; //// My Boostrap custom variables
@import "../../vendor/bootstrap-4.0.0-beta/scss/mixins";
@import "../../vendor/bootstrap-4.0.0-beta/scss/print";
@import "../../vendor/bootstrap-4.0.0-beta/scss/reboot";
@import "../../vendor/bootstrap-4.0.0-beta/scss/type";
@import "../../vendor/bootstrap-4.0.0-beta/scss/images";
@import "../../vendor/bootstrap-4.0.0-beta/scss/code";
@import "../../vendor/bootstrap-4.0.0-beta/scss/grid";
@import "../../vendor/bootstrap-4.0.0-beta/scss/tables";
@import "../../vendor/bootstrap-4.0.0-beta/scss/forms";
@import "../../vendor/bootstrap-4.0.0-beta/scss/buttons";
@import "../../vendor/bootstrap-4.0.0-beta/scss/transitions";
@import "../../vendor/bootstrap-4.0.0-beta/scss/dropdown";
@import "../../vendor/bootstrap-4.0.0-beta/scss/button-group";
@import "../../vendor/bootstrap-4.0.0-beta/scss/input-group";
@import "../../vendor/bootstrap-4.0.0-beta/scss/custom-forms";
@import "../../vendor/bootstrap-4.0.0-beta/scss/nav";
@import "../../vendor/bootstrap-4.0.0-beta/scss/navbar";
@import "../../vendor/bootstrap-4.0.0-beta/scss/card";
@import "../../vendor/bootstrap-4.0.0-beta/scss/breadcrumb";
@import "../../vendor/bootstrap-4.0.0-beta/scss/pagination";
@import "../../vendor/bootstrap-4.0.0-beta/scss/badge";
@import "../../vendor/bootstrap-4.0.0-beta/scss/jumbotron";
@import "../../vendor/bootstrap-4.0.0-beta/scss/alert";
@import "../../vendor/bootstrap-4.0.0-beta/scss/progress";
@import "../../vendor/bootstrap-4.0.0-beta/scss/media";
@import "../../vendor/bootstrap-4.0.0-beta/scss/list-group";
@import "../../vendor/bootstrap-4.0.0-beta/scss/close";
@import "../../vendor/bootstrap-4.0.0-beta/scss/modal";
@import "../../vendor/bootstrap-4.0.0-beta/scss/tooltip";
@import "../../vendor/bootstrap-4.0.0-beta/scss/popover";
@import "../../vendor/bootstrap-4.0.0-beta/scss/carousel";
@import "../../vendor/bootstrap-4.0.0-beta/scss/utilities";

// Other third-party stylesheets
@import "../../vendor/Magnific-Popup-1.1.0/src/css/main";
@import "../../vendor/slick-1.6.0/slick/slick.scss";
@import "../../vendor/slick-1.6.0/slick/slick-theme.scss";

// Web Fonts
@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i";
@import "https://fonts.googleapis.com/css?family=Exo:900i";

// Website styles
@import "site";


// Nasty browser hacks (only targets IE 6-10, not 11.)
@media screen\9 { @import "iestyles"; }
@media screen\0 { @import "iestyles"; }
