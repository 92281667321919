//
// Color system
//

$gray-100: #f5f5f5;
$gray-200: #eaeaeb;
$gray-300: #d6d6d7;
$gray-400: #c1c1c2;
$gray-500: #a2a2a4;
$gray-600: #6D6E70;
$gray-700: #404041;
$gray-800: #323234;
$gray-900: #282829;

// Sanity spelling of grey
$grey-darkest:              $gray-900;
$grey-darker:               $gray-800;
$grey-dark:                 $gray-700;
$grey:                      $gray-600;
$grey-light:                $gray-400;
$grey-lighter:              $gray-300;
$grey-lightest:             $gray-100;
$grey-100: $gray-100;   $grey-200: $gray-200;   $grey-300: $gray-300;
$grey-400: $gray-400;   $grey-500: $gray-500;   $grey-600: $gray-600;
$grey-700: $gray-700;   $grey-800: $gray-800;   $grey-900: $gray-900;

// Colour overrides and additional colours
$yellow:					#E29926;
$red:						#EC1C24;
$green-light:				#AED136;
$blue:						#00A1DF;
$bronze:					#DDB56A;

// Reassign color vars to semantic color scheme
$brand-primary:             $red;
$brand-secondary:           $blue;
$brand-success:             $green;
$brand-info:                $cyan;
$brand-warning:             $yellow;
$brand-danger:              $red;
$brand-inverse:             $grey-dark;

$brand-facebook:            #3b5998;
$brand-twitter:             #00aced;
$brand-instagram:           #cd486b;

$theme-colors: (
  primary: $red,
  secondary: $blue,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false;
$enable-shadows:            true;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-headings: 'Exo', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

