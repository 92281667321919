/* ===============================================================================================================================
   HELPERS, BOOTSTRAP OVERRIDES AND BASE ELEMENT RESTYLING
   ============================================================================================================================ */

// * { transition: color 0.3s ease, background 0.3s ease, border-color 0.3s ease, padding 0.3s ease, margin 0.3s ease, 
// 	height 0.3s ease, width 0.3s ease, min-height 0.3s ease, min-width 0.3s ease, max-height 0.3s ease, max-width 0.3s ease, 
// 	scale 0.3s ease, opacity 0.3s ease, filter 0.3s ease; }
a { transition: color 0.4s ease, background 0.4s ease, border-color 0.4s ease, opacity 0.4s ease, filter 0.4s ease; }

a:not(.btn) u { @include smart-underline(#fff, $grey, $brand-secondary, 76%); text-decoration: none; }
a:not(.btn):hover { text-decoration: none; }
a:not(.btn):hover u { @include smart-underline(#fff, $brand-secondary, $brand-secondary, 76%); }

h1, h2, h3, h4, h5, h6 { color: $grey; }
h2 { text-transform: uppercase; font-weight: bold; }

.text-inverse {
	color: #fff;
	a:not(.btn) { color: #fff; }
	a:not(.btn):hover { color: $brand-secondary; }
	a:not(.btn) u { @include smart-underline($grey-dark, $grey-light, $brand-secondary, 76%); color: $yellow; }
	a:not(.btn):hover u { @include smart-underline($grey-dark, $brand-secondary, $brand-secondary, 76%); color: #fff; }
	h1, h2, h3, h4, h5, h6 { color: $grey-lighter; }
}
.text-primary { color: $brand-primary !important; }
.text-secondary { color: $brand-secondary !important; }
.text-white { color: $white !important; }
.text-black { color: $black !important; }
.text-grey-darkest { color: $grey-darkest !important; }
.text-grey-darker { color: $grey-darker !important; }
.text-grey-dark { color: $grey-dark !important; }
.text-grey { color: $grey !important; }
.text-grey-light { color: $grey-light !important; }
.text-grey-lighter { color: $grey-lighter !important; }
.text-grey-lightest { color: $grey-lightest !important; }

.bg-primary { background: $brand-primary !important; }
.bg-secondary { background: $brand-secondary !important; }
.bg-white { background: $white !important; }
.bg-black { background: $black !important; }
.bg-grey-darkest { background: $grey-darkest !important; }
.bg-grey-darker { background: $grey-darker !important; }
.bg-grey-dark { background: $grey-dark !important; }
.bg-grey { background: $grey !important; }
.bg-grey-light { background: $grey-light !important; }
.bg-grey-lighter { background: $grey-lighter !important; }
.bg-grey-lightest { background: $grey-lightest !important; }

.heading-small-caps { text-transform: uppercase; font-size: 1rem; font-weight: bold; }

.spaced { padding-top: $spacer*2; padding-bottom: $spacer*2; }
.spaced.extra { padding-top: $spacer*3; padding-bottom: $spacer*3; }

.alert:not(.alert-info):not(.alert-warning):not(.alert-success):not(.alert-danger) { 
	background-color: $grey-lightest; border-color: $grey-lighter; color: $grey-dark; }
.alert :first-child { margin-top: 0; }
.alert :last-child { margin-bottom: 0; }

td h1, td h2, td h3, td h4, td h5, td h6, th h1, th h2, th h3, th h4, th h5, th h6 { 
	margin-top: $spacer/2 !important; margin-bottom: 0 !important; }

h1:first-child,  h2:first-child,  h3:first-child,  h4:first-child,  h5:first-child,  h6:first-child {
	margin-top: 0; }
p:last-child { margin-bottom: 0; }

.btn { text-transform: uppercase; font-weight: bold; }


/* ===============================================================================================================================
   HEADER AREA
   ============================================================================================================================ */

.header {
	background: $grey-dark;
	
	.row { align-items: center; }
	
	.site-logo-area { padding-top: $spacer*2; padding-bottom: $spacer; }
	
	.brands-nav-area { 
		padding-bottom: $spacer; 
		.nav { justify-content: center; width: 100%; flex-direction: row; }
		.nav li { display: flex; flex: 0 0 33.3%; text-align: center; margin-top: $spacer*0.5; margin-bottom: $spacer*0.5; }
		.nav-link { position: relative; display: block; padding: 0; text-align: center; color: $grey-lighter; 
			text-transform: uppercase; font-weight: bold; font-size: 0.6rem; transition: all 0.5s ease-out; }
		.nav-link img { display: block; margin-bottom: $spacer*0.25; padding: 0 $spacer; max-width: 75px; }
		.nav-link-auto:hover { color: lighten($yellow, 20%); }
		.nav-link-industrial:hover { color: lighten($red, 20%); }
		.nav-link-architectural:hover { color: lighten($green-light, 20%); }
		.nav-link-marine:hover { color: lighten($blue, 20%); }
		.nav-link-timber:hover { color: lighten($bronze, 20%); }
		.nav-link:hover img { animation: shine 0.5s 1 forwards; }
		@keyframes shine {
			0% { filter:contrast(130%) brightness(150%); }
			100% { filter:contrast(110%) brightness(110%); }
		}
	}
	@include media-breakpoint-up(md) {
		.site-logo-area { padding-bottom: $spacer*2; }
		.brands-nav-area { padding-bottom: 0; }
	}
	@media (min-width: 430px) {
		.brands-nav-area { 
			.nav li { flex: 0 0 20%; margin-top: 0; margin-bottom: 0; }
			.nav-link img { padding: 0 $spacer*0.5; }
		}
	}
}

.nav-area { 
	background: $grey; 
	.nav { justify-content: space-around; width: 100%; }
	.nav li { display: flex; flex-basis: 50%; text-align: center; }
	.nav a { color: #fff; font-weight: bold; text-transform: uppercase; width: 100%; }
	.nav a:hover { color: $yellow; }
	
	@include media-breakpoint-up(sm) {
		.nav li { flex-basis: 25%; }
	}
}


/* ===============================================================================================================================
   MAIN CONTENT AREA
   ============================================================================================================================ */

/* HOME PAGE COMPONENTS
   ---------------------------------------------------------------------------------------------------------------------------- */

.slideshow { 
	background: $grey-200; 
	.container { padding-right: 0; padding-left: 0; }
	.container .slide:not(:first-child) { display: none; }
	.container.slick-initialized .slide:not(:first-child) { display: block; }
	.slick-prev:before, .slick-next:before { color: $red; }
	.slide {
		position: relative; 
		.slide-overlay { 
			position: absolute; right: 0; bottom: 0; padding: $spacer/3 $spacer; background: rgba($red, 0.8); text-align: right; 
			h3 { font-weight: bold; color: #fff; line-height: 1; font-size: 1rem; margin-bottom: 0; }
			p { color: $grey-lighter; line-height: 1.3; font-size: 0.85rem; }
		}
	}
}
@include media-breakpoint-up(sm) {
	.slideshow .slide .slide-overlay { padding: $spacer/2 $spacer; }
	.slideshow .slide .slide-overlay h3 { font-size: 1.25rem; }
	.slideshow .slide .slide-overlay p { font-size: 0.9rem; }
}
@include media-breakpoint-up(md) {
	.slideshow .container { padding-right: 15px; padding-left: 15px; }
	.slideshow .slide .slide-overlay { padding: $spacer; }
	.slideshow .slide .slide-overlay h3 { font-size: 1.75rem; }
	.slideshow .slide .slide-overlay p { font-size: 1rem; }
}

.blurb p:first-of-type { @extend .lead; }

.home-brands {
	background: $grey-lighter; 
	
	.card-header { background: $grey-dark; color: $white; text-transform: uppercase; font-size: 0.9rem; font-weight: bold; 
		text-align: center; }
	.card-body { 
		font-size: 0.9rem; 
		.logo { 
			margin-bottom: $spacer; height: 90px; display: flex; align-items: center; justify-content: center; text-align: center; 
			a, img { max-height: 90px; max-width: 100%; flex: 1 1; }
		}
	}
	.card-footer { 
		padding: 0; 
		.btn { font-size: 0.9rem; }
	}
}


/* SUB-PAGES
   ---------------------------------------------------------------------------------------------------------------------------- */

// Locations

.location-filter .btn { cursor: pointer; }
.icon-search { width: 1.2rem; height: 1.2rem; }

.location-header, .location-item { align-items: center; padding: $spacer/3 0; margin: 0;}
.location-header { text-transform: uppercase; padding: $spacer 0; color: #fff; font-weight: bold; background: $brand-primary; }
.location-item { background: $grey-100; }
.location-item:nth-child(2n+1) { background: $grey-200; }
.location-title { font-weight: bold; }
.location-brands { white-space: nowrap; }
.location-brands img { width: 40px; height: 40px; margin-right: 4px; }
.location-brands img:last-child { margin-right: 0; }
.location-address { font-size: 0.9rem }

@include media-breakpoint-down(sm) {
	.location-filter { 
		padding-top: $spacer; padding-bottom: $spacer; 
		
		.input-group { justify-content: center; flex-wrap: wrap; }
		.input-group-btn { margin: 2px 2px; }
		.input-group-btn .btn {  }
	}
}
@include media-breakpoint-up(md) {
	.location-header, .location-item { padding: $spacer/2 0; }
	.location-brands { margin-top: $spacer/2; margin-bottom: -$spacer; }
}
@include media-breakpoint-up(lg) {
	.location-filter .btn { padding: $spacer/2 $spacer; font-size: 1.25rem; line-height: 1.5; }
	.icon-search { width: 1.5rem; height: 1.5rem; }
	
	.location-header, .location-item { padding: $spacer 0; }
	.location-brands { margin-top: 0; }
}

// Brands

.brand-icon { 
	flex-direction: column; margin-right: $spacer; text-align: center; 
	img { width: 120px; margin: 0 auto; }
	div { font-weight: bold; font-size: 1.2rem; margin-top: $spacer/2; text-transform: uppercase; }
}
.brand-logos { display: flex; flex-wrap: wrap; align-items: center; }
.brand-logos img { padding: 10px; max-height: 100px; max-width: 200px; }

@include media-breakpoint-down(xs) {
	.brand-item { display: block; }
	.brand-logos { justify-content: center; }
}
@include media-breakpoint-up(sm) {
	.brand-logos img { padding: 10px 10px 10px 0; max-height: 110px; max-width: 170px; }
	.brand-logos img:last-child { margin-right: 0; }
}
@include media-breakpoint-up(md) {
	.brand-icon, .media-body { align-self: center; }
	.brand-icon img { width: 150px; }
	.brand-logos img { max-height: 110px; max-width: 160px; }
}
@include media-breakpoint-up(lg) {
	.brand-icon { margin-right: $spacer*2; }
	.brand-icon img { width: 150px; }
	.brand-logos img { max-height: 110px; max-width: 230px; }
}
@include media-breakpoint-up(xl) {
	.brand-icon { margin-right: $spacer*3; }
	.brand-icon img { width: 200px; }
	.brand-logos img { max-height: 120px; max-width: 270px; }
}

// Contact

big { font-size: 2rem; }

#imessageERROR { display: none; }
.iform .ilabel { margin-bottom: 0px; margin-top: 9px; }
label em { font-style: normal; font-weight: bold; color: $yellow; }
.captcha p { font-size: 0.9rem; }
.captcha img { margin-bottom: $spacer/2; }




/* ===============================================================================================================================
   FOOTER AREA
   ============================================================================================================================ */

.footer {
	background: $grey-dark; padding: $spacer 0;
	
	.row { align-items: center; }
	.footer-details { color: $grey-lighter; }
	.footer-cta { 
		font-size: 1.6rem; 
		strong { color: #fff; }
	}
}